import React from 'react'
import { FeatureWrapper } from './styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, Span, SpanStyled, TextStyled } from '../../Styled/Elements'
import { AllInOne, Arabic, ArrowIcon, BtnArrow, English, FeatureImg, Meeting, Permission, Reporting, Service } from '../../Styled/AllImages'
import { ButtonWithIcon, TextWithIcon } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'

const Features = () => {
    const { t } = useTranslation();
    return (
        <FeatureWrapper className='space-top-100' id='feature'>
            <Container>
                <Row >
                    <Col >
                        <Box className='Heading-Content'>
                            <HeadingStyled className='subHeading'>{t('Explore')}</HeadingStyled>
                            <MainHeading className='MainHeading'>{t('Unparalleled')}</MainHeading>
                            <TextStyled className='paragraph'>{t('Revolutionize')}</TextStyled>
                        </Box>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='space-bottom-100 align-items-center border-bottom-line'>
                    <Col lg={6} md={12} className='order-1'>
                        <Box className='service-img-end '>
                            <LazyImage src={Service} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box className='service-content-wrapper'>
                            <SpanStyled size='24px' color="var(--yellow)">{t('Experience_with')}</SpanStyled>
                            <HeadingStyled weight='bold' color="var(--themeColor)"> {t('Cloud_based_Service')}</HeadingStyled>
                            <TextStyled className='paragraph'>{t('Experience_with_content')}</TextStyled>
                            <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                        </Box>
                    </Col>
                </Row>
                <Row className='space-100 align-items-center border-bottom-line'>
                    <Col lg={6} md={12} >
                        <Box className='service-img-start'>
                            <LazyImage src={AllInOne} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12} className='order-1'>
                        <Box className='service-content-wrapper'>
                            <SpanStyled size='24px' color="var(--yellow)">{t('Effortless')}</SpanStyled>
                            <HeadingStyled weight='bold' color="var(--themeColor)"> {t('Solution')}</HeadingStyled>
                            <TextStyled className='paragraph'>{t('Solution_PRM')}</TextStyled>
                            <HeadingStyled size='20px' weight='bold' color="var(--themeColor)">{t('Tools_Included')}:</HeadingStyled>
                            <Box className='my-3'>
                                <TextWithIcon icon={ArrowIcon} title={t('Inventory_Tracking')} />
                                <TextWithIcon icon={ArrowIcon} title={t('Items_Requesting')} />
                                <TextWithIcon icon={ArrowIcon} title={t('Purchase_Requesting')} />
                                <TextWithIcon icon={ArrowIcon} title={t('Offers_Analysis')} />
                            </Box>
                            <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                        </Box>
                    </Col>
                </Row>
                <Row className='space-100 align-items-center border-bottom-line'>
                    <Col lg={6} md={12} className='order-1' >
                        <Box className='service-img-end'>
                            <LazyImage src={Meeting} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box className='service-content-wrapper'>
                            <SpanStyled size='24px' color="var(--yellow)">{t('Collaboration_Hub')}</SpanStyled>
                            <HeadingStyled weight='bold' color="var(--themeColor)">{t('Meetings_Messages')}</HeadingStyled>
                            <TextStyled className='paragraph'>{t('Meetings_Messages_content')}</TextStyled>
                            <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                        </Box>
                    </Col>
                </Row>
                <Row className='space-100 align-items-center border-bottom-line'>
                    <Col lg={6} md={12}  >
                        <Box className='service-img-start'>
                            <LazyImage src={Permission} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12} className='order-1'>
                        <Box className='service-content-wrapper'>
                            <SpanStyled size='24px' color="var(--yellow)">{t('Total_Control_with')}</SpanStyled>
                            <HeadingStyled weight='bold' color="var(--themeColor)"> {t('Rights_Permissions')}!</HeadingStyled>
                            <TextStyled className='paragraph'>{t('Rights_Permissions_content')}</TextStyled>
                            <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                        </Box>
                    </Col>
                </Row>
                <Row className='space-100 align-items-center'>
                    <Col lg={6} md={12} className='order-1' >
                        <Box className='service-img-end'>
                            <LazyImage src={Reporting} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box className='service-content-wrapper'>
                            <SpanStyled size='24px' color="var(--yellow)">{t('Insights')}</SpanStyled>
                            <HeadingStyled weight='bold' color="var(--themeColor)">{t('Reporting_with_PRM')}</HeadingStyled>
                            <TextStyled className='paragraph'>{t('Reporting_with_PRM_content')}</TextStyled>
                            <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                        </Box>
                    </Col>
                </Row>
            </Container>
            <Container >
                <Box className='feature-bg'>
                    <Row className='feature-row'>
                        <Box className='feature-box'>
                            <Box className=''>
                                <HeadingStyled color='var(--white)' className='subHeading'>{t('trusted_partner')}</HeadingStyled>
                                {/* <TextStyled className='mt-4' color='var(--white)'>
                                    {t('english_arabic')}
                                    <LazyImage src={Arabic} />
                                    <LazyImage src={English} />
                                </TextStyled> */}
                            </Box>
                            <Span className='feature-img'>
                                <LazyImage className='' src={FeatureImg} />
                            </Span>
                            <Box className=''>
                                <HeadingStyled color='var(--white)' className='subHeading mb-4'>{t('Top_notch')} </HeadingStyled>
                                <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                            </Box>
                        </Box>
                    </Row>
                </Box>
            </Container>
        </FeatureWrapper>
    )
}

export default Features
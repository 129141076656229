import { LazyImage } from "../Styled/Elements";
import {
  AllUser,
  Communicate,
//   DashBoard,
  Dashboard,
  Far,
  Inventory,
  Item,
  Message,
  Offer,
  Order,
  Purchase,
  Report,
  Settings,
  Video1,
  Video2,
} from "../Styled/AllImages";
import { ControlBar, PlayToggle, Player } from "video-react";

export const NavDataConfig = [
  {
    to: "home",
    label: "Home",
  },
  {
    to: "about",
    label: "About",
  },
  {
    to: "feature",
    label: "Features",
  },
  {
    to: "howItWork",
    label: "howItWork",
  },
  {
    to: "contact",
    label: "contactUs",
  },
];
export const AllSolution = [
  {
    icon: <Dashboard />,
    heading: "Dashboard",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <AllUser />,
    heading: "Profile",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Inventory />,
    heading: "Inventory",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Purchase />,
    heading: "Purchase_Request",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Item />,
    heading: "Item_Request",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Order />,
    heading: "Direct_Purchase_Request",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Offer />,
    heading: "Offer_Analysis",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Far className="report" />,
    heading: "FAR",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Report className="report" />,
    heading: "Reports",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Communicate />,
    heading: "Meetings",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Message />,
    heading: "Messages",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <AllUser />,
    heading: "Users",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
  {
    icon: <Settings />,
    heading: "Settings",
    text: "Streamline",
    video: (
      <Player autoPlay poster="/assets/poster.png" src={Video1}>
        <ControlBar autoHide={false} disableDefaultControls={true}>
          <PlayToggle />
        </ControlBar>
      </Player>
    ),
  },
];

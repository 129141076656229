import React from 'react'
import { ContactWrapper } from './styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../../Styled/Elements'
import { TextWithIcon } from '../../Styled/CustomElements'
import { Call, ContactImg, Email, Facebook, Instagram, Location, Snapchat, Twitter } from '../../Styled/AllImages'
import ContactForm from './ContactForm'
import { useTranslation } from 'react-i18next'

const Contact = () => {
    const { t } = useTranslation();
    return (
        <ContactWrapper id='contact'>
            <Container className=''>
                <Box className='contact-bg'>
                    <Row className='contact-row'>
                        <Col lg={4} md={12}>
                            <Box className='contact-wrap'>
                                <SpanStyled>
                                    <HeadingStyled color="var(--yellow)">{t('Contact_heading')}</HeadingStyled>
                                    <TextStyled color="var(--white)">{t('Contact_heading_us')}</TextStyled>
                                </SpanStyled>
                                <SpanStyled>
                                    <TextWithIcon color='var(--white)' icon={Call} title={t('phone_no')} />
                                    <TextWithIcon color='var(--white)' icon={Email} title='info@prm.sa' />
                                    <TextWithIcon color='var(--white)' icon={Location} title={t('address')} />
                                </SpanStyled>
                                <SpanStyled className='my-4'>
                                    <HeadingStyled size='20' color="var(--white)" >{t('Follow_us')}</HeadingStyled>
                                    <Box className='d-flex gap-3 my-3'>
                                        <Facebook />
                                        <Twitter />
                                        <Instagram />
                                        <Snapchat />
                                    </Box>
                                </SpanStyled>
                            </Box>
                        </Col>
                        <Col lg={3} className='Position-relative'>
                            {/* <Box className='contact-img'>
                                <LazyImage src={ContactImg} />
                            </Box> */}
                        </Col>
                        <Col lg={5} md={12}>
                            <Box>
                                <ContactForm />
                            </Box>
                        </Col>

                    </Row>
                </Box>
            </Container>
        </ContactWrapper>
    )
}

export default Contact
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, TextStyled } from '../../Styled/Elements'
import { AboutImg, BtnArrow } from '../../Styled/AllImages'
import { ButtonWithIcon } from '../../Styled/CustomElements'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const About = () => {
    const { t } = useTranslation();
    return (
        <AboutWrapper id='about'>
            <Container>
                <Row>
                    <Col>
                        <Box className='Heading-Content space-50' >
                            <HeadingStyled className='subHeading'>{t('Elevating')}</HeadingStyled>
                            <MainHeading className='MainHeading'> {t('PR_Management')}</MainHeading>
                            <TextStyled className='paragraph'>{t('PR_Management_content')}</TextStyled>
                        </Box>
                    </Col>
                </Row>
            </Container>
            <Container >
                <Box className='about-bg'>
                    <Row className='space-25 align-items-center' >
                        <Col lg={6} md={12}>
                            <Box className='about-img'>
                                <LazyImage src={AboutImg} />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='about-content-wrap'>
                                <HeadingStyled color="var(--white)" size="26px">{t('Elevate_PRM')}</HeadingStyled>
                                <TextStyled className='paragraph' color="var(--white)">{t('Experience')} </TextStyled>
                                <ButtonWithIcon bg="var(--themeColor)" icon={BtnArrow} label={t('button')} url="https://portal.prm.sa" />
                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </AboutWrapper>
    )
}


export default About

const AboutWrapper = styled.section`

.about-img{
    text-align:end;
    position: relative;
    top: 25px;
}
.about-content-wrap{
    width:80%;
}
`


import React, { useState } from 'react'
import { AllSolutionWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, Span, TextStyled } from '../../Styled/Elements'
import { ArrowDown, ArrowUp, Video1 } from '../../Styled/AllImages'
import { AllSolution } from '../../data/Data'
import { useTranslation } from 'react-i18next'


const AllInOne = () => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const toggleItem = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    return (
        <AllSolutionWrapper className='space-25' id='howItWork'>
            <Container>
                <Row>
                    <Col>
                        <Box className='Heading-Content'>
                            <MainHeading size='32px' className='MainHeading'>{t('Solution')}</MainHeading>
                            <TextStyled className='paragraph'>{t('Solution_compromise')}</TextStyled>
                        </Box>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                    <Col lg={4} md={12}>
                        <Box>
                            {AllSolution.map((list, index) => (
                                <Box key={index} className={`item-container  ${activeItem === index ? 'active-item-box' : ''}`}>
                                    <Box className='item-box border-bottom-line ' onClick={() => toggleItem(index)}>
                                        <Span className='d-flex gap-2 align-items-center'>
                                            {list.icon}
                                            {t(list.heading)}
                                        </Span>
                                        {activeItem === index ? <ArrowUp /> : <ArrowDown />}
                                    </Box>
                                    {activeItem === index && (
                                        <Box>
                                            <Box>
                                                <TextStyled size='16px' color='var(--white)'>{t(list.text)}</TextStyled>
                                            </Box>
                                            <Box className=' mobile-view'>
                                                {list.video}
                                                {/* <Player autoPlay poster="/assets/poster.png" src={list.video}>
                                                    <ControlBar autoHide={false} disableDefaultControls={true}>
                                                        <PlayToggle />
                                                    </ControlBar>
                                                </Player> */}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Col>
                    <Col lg={8} md={12} >
                        <Box className='w-100 h-100 desktop-view'>
                            {AllSolution.map((list, index) => (
                                <Box key={index} className={`${activeItem === index ? '' : ''}`} >
                                    {activeItem === index && (
                                        <Box className=''>
                                            {list.video}
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Col>
                </Row>
            </Container>
        </AllSolutionWrapper >
    )
}

export default AllInOne